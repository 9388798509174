import { initFederation } from '@angular-architects/native-federation';

import { environment } from '@/environments/environment';

const federationManifest = {
  'iomt-dm-swd-fe': environment.swdRemoteEntryUrl,
  'iomt-dm-di-fe': environment.diRemoteEntryUrl
};

initFederation(federationManifest)
  .catch((err) => console.error(err))
  .then((_) => import('./bootstrap'))
  .catch((err) => console.error(err));
